<template>
  <div class="job-board">
    <div class="job-board-detail-component">
      <div class="container">
        <div class="row logo-summary mt-3">
          <div
            class="
              col-lg-4 col-sm-12 col-md-12
              d-flex
              justify-content-center
              align-items-center
              p-0
            "
          >
            <div class="d-flex flex-column align-items-center">
              <div class="text-center" style="width: 40%; margin-top: 16px">
                <img
                  :src="fileSource.logo"
                  class="rounded-circle w-100 h-100"
                  style="w-100 h-100"
                  @error="$event.target.src = '/img/hospital.png'"
                />
              </div>
              <div class="my-3">
                <h6 class="text-primary text-center">{{ organisation }}</h6>
                <div class="location text-primary">
                  <p>
                    <i class="fas fa fa-map-marker-alt"></i>&nbsp;
                    <span style="color: #00000099">{{ location }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-sm-12 col-md-12">
            <div class="title">
              <p class="text-left">{{ title }}</p>
            </div>
            <div class="d-flex">
              <div class="detail-group">
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Type</div>
                  <div class="value">{{ candidateType }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Level</div>
                  <div class="value">{{ candidateLevel }}</div>
                </div>
              </div>

              <div class="detail-group text-left">
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Job Reference</div>
                  <div class="value">{{ jobReference }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Contract</div>
                  <div class="value">{{ contract }}</div>
                </div>
              </div>

              <div class="detail-group">
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Date Posted</div>
                  <div class="value">{{ date_created }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Expires on</div>
                  <div class="value">
                    {{ expiryDate }} |
                    <span style="font-weight: 500">{{ expiryDays }}</span>
                  </div>
                </div>
              </div>
              <social-sharing
                v-if="false"
                :url="url"
                :title="title"
                :description="jobReference"
                inline-template
              >
                <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
                  <template #toggler-content>
                    <div class="d-flex align-items-center text">
                      <CIcon name="cil-share-alt" class="color-primary" />
                    </div>
                  </template>
                  <CDropdownItem>
                    <network network="email">
                      <i class="fa fa-envelope"></i> Email
                    </network>
                  </CDropdownItem>
                  <div>
                    <CDropdownItem>
                      <network network="facebook">
                        <i class="fa fa-facebook"></i> Facebook
                      </network>
                    </CDropdownItem>
                  </div>
                </CDropdown>
              </social-sharing>
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="detail-group">
                <button
                  v-if="!getApplicationStatus"
                  type="button"
                  class="btn btn-primary mb-3"
                  @click="$parent.onApplyJob()"
                >
                  Apply Now
                </button>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  v-else
                  @click="$parent.onWithdrawJob()"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 100%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
        />
      </div>

      <div class="pr-2 pl-3 mt-3" v-html="jobDescription"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import m from "moment";
import { BASE_URL } from "@/service_urls";

export default {
  data() {
    return {
      successModal: false,
      urlFullPath: "",
      modalMessage: "",
      isApplyingToJob: false,
    };
  },
  created() {
    const {
      fullPath,
      params: { id },
    } = this.$route;
    this.urlFullPath = fullPath;
  },
  computed: {
    ...mapGetters([
      "getSelectedJobBoard",
      "getRoleScopes",
      "getApplicationStatus",
      "getSelectedJobBenefitsAndAdvertDesc",
    ]),
    isPublished() {
      return this.getSelectedJobBoard?.published;
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
    candidateType() {
      return this.getSelectedJobBoard?.candidate_type?.candidate_type || "--";
    },
    candidateLevel() {
      return this.getSelectedJobBoard?.level_names?.join(" ") || "--";
    },
    featured() {
      return this.getSelectedJobBoard?.featured ? "Yes" : "No";
    },
    organisation() {
      return this.getSelectedJobBoard?.organisation?.name || "--";
    },
    jobDescription() {
      return this.getSelectedJobBenefitsAndAdvertDesc?.advert_description || "";
    },
    location() {
      return this.getSelectedJobBoard?.location?.location || "--";
    },
    jobReference() {
      return `${this.getSelectedJobBoard?.job_id}` || "--";
    },
    title() {
      return this.getSelectedJobBoard?.job_title || "";
    },
    status() {
      return this.getSelectedJobBoard?.status?.status || "";
    },
    appliedOn() {
      let appliedOn = this.getSelectedJobBoard?.created_on || "";
      return moment(appliedOn).format("YYYY-MM-DD");
    },
    contract() {
      if (this.getSelectedJobBoard) {
        const { permanent_work, temporary_work } = this.getSelectedJobBoard;
        if (permanent_work && temporary_work) return "Permanent;Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
        else return "--";
      } else {
        return "--";
      }
    },
    date_created() {
      return this.formatDate(this.getSelectedJobBoard?.created_on);
    },
    expiryDate() {
      return this.formatDate(this.getSelectedJobBoard?.expiry_review_date);
    },
    expiryDays() {
      let days = 0;
      if (this.getSelectedJobBoard.expiry_review_date) {
        let diff = moment(this.getSelectedJobBoard.expiry_review_date).diff(
          moment(),
          "days"
        );
        days = diff < 0 ? "Expired" : `${diff} Days`;
      }
      return days;
    },
    url() {
      return window?.location?.href;
    },
    hospital() {
      return this.getSelectedJobBoard?.hospital || "--";
    },
    fileSource() {
      return {
        logo: this.getSelectedJobBoard?.organisation?.logo_url || "",
        image:
          this.getSelectedJobBoard?.organisation?.default_job_banner_url || "",
      };
    },
    favouriteID() {
      return this.getSelectedJobBoard?.ids?.favourite_id || null;
    },
    appliedID() {
      return this.getSelectedJobBoard?.ids?.applied_id || null;
    },
  },
  methods: {
    ...mapActions([
      "applyJob",
      "cancelJob",
      "showToast",
      "withdrawJob",
      "addPreferredJob",
      "deletePreferredJob",
      "getPreScreeningQuestions",
    ]),
    onApplyJob() {
      this.successModal = true;
      if (this.getRoleScopes.length) {
        const { job_id } = this.getSelectedJobBoard;
        this.isApplyingToJob = true;
        this.modalMessage = "Are you sure you want to apply for this job?";
        this.previewPreScreeningModal.jobInfo = this.getSelectedJobBoard;
        this.$store.commit("RESET_PRE_SCREENING_QUESTIONS");
        this.getPreScreeningQuestions(job_id);
      } else {
        this.modalMessage = "Please Login/Register to apply for the jobs";
      }
    },
    onWithdrawJob() {
      this.successModal = true;
      this.modalMessage = "Are you sure you want to withdraw from this job?";
    },
    onShow(e, value) {},
    numberWithCommas(number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    favouriteJob(favourite_id) {
      let { job_id } = this.getSelectedJobBoard;
      const preferred_type = "favourite";
      if (!favourite_id) {
        this.addPreferredJob({ job_id, preferred_type });
      } else {
        this.deletePreferredJob({
          job_id,
          preferred_id: favourite_id,
          preferred_type,
        });
      }
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}
.title > p {
  color: #00626a !important;
}
.text-primary {
  color: #00626a !important;
}
.btn-primary {
  background-color: #00626a !important;
  border-color: #00626a !important;
}
</style>
